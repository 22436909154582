// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueItem } from "@/models/TextValueItem";
import { EseguiComandoRequest } from "../../models/comandi/eseguiComandoRequest";
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import ElencoAdesioniPerPagamentoEditItem from "@/models/registrazionePagamenti/elencoAdesioniPerPagamentoEditItem";
import CustomEditAction from '../../models/customEditAction';
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.REGISTRAZIONE_PAGAMENTI,
	_ENDPONT_TOTALI = api.endpoint.REGISTRAZIONE_PAGAMENTI_TOTALI;


@Component({})
export default class registrazionePagamentiPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Pagamenti.REGISTRAZIONE]; }
	editItem: ElencoAdesioniPerPagamentoEditItem = new ElencoAdesioniPerPagamentoEditItem();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyECbalancePositive: boolean = true;
	totaliGenerali: any = {};
	kendop: any = kendo;

	actionsToDo: TextValueItem[] = [
		{ value: null, text: "Esegui su selezione" },
		{ value: 1, text: "Invia tutti gli EC non inviati" },
		{ value: 2, text: "Invia EC hai selezionati" },
		{ value: 3, text: "Sollecito EC hai selezionati" },
		{ value: 4, text: "Ripeti generazione EC selezionati" },
		{ value: 5, text: "Salda adesioni per EC selezionati" },
		{ value: 6, text: "Apri Report Completo Periodo Specificato" },
		{ value: 7, text: "Apri Report Completo Anno Specificato" }
	];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ItemID", width: 80, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, hidden: true },

			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.ragioneSociale").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				editHide: true,
				export: true,
				width: 200
			},
			{
				field: "numeroAdesione",
				title: "N. Adesione",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				width: 150,
				filterable: true,
				filterType: "numeric",
				editDisabled: true,
				export: true,
			},
			{
				field: "destinazione",
				title: this.$i18n.t("generico.destinazione").toString(),
				export: true,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				editHide: true
			},
			{
				field: "dataAcquisto",
				title: this.$i18n.t("generico.dataAcquisto").toString(),
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "partecipantiElenco",
				title: this.$i18n.tc("generico.partecipante_i", 2).toString(),
				width: 200,
				type: "renderfunction",
				renderFunction: this.renderCellPartecipantiElenco,
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{
				field: "totalePremioDaPagare",
				title: this.$i18n.t("generico.daPagare").toString(),
				width: 140,
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterDaPagareCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editDisabled: true,
				export: true
			},
			{
				field: "importoSconto",
				title: "Sconto",
				width: 140,
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterScontoCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "importoPagato",
				title: "Pagato",
				width: 140,
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterPagatoCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "dataPagamento",
				title: "DataPagamento",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "saldo",
				title: "Saldo",
				width: 140,
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterSaldoCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true,
				editDisabled: true,
			},
			{
				field: "pagamento",
				title: "CP",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				editHide: true,
				export: true,
			},

			{ field: "_actions", title: " ", width: 50, cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" },
		];
	}

	created() {
		this.filter.filters.push({
			field: "saldo",
			operator: "neq",
			value: "!!n=0"
		});

		
		this.calcolaTotaliGenerali();
		this.getData();
	}

	mounted() { }

	@Watch("showOnlyECbalancePositive")
	onShowOnlyECbalancePositiveChnage(newValue: boolean) {
		const field = "saldo";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "neq",
				value: "!!n=0"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function (item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
		this.calcolaTotaliGenerali();
	}

	onSaldaAction() {
		if (this.editItem) {
			let daPagare = this.editItem.totalePremioDaPagare || 0;
			let sconto = this.editItem.importoSconto;
			let saldo = daPagare - sconto;

			this.editItem.importoPagato = saldo;
			this.editItem.saldo = 0;
		}
	}

	renderCellPartecipantiElenco(data: string) {
		var array = JSON.parse(data) as PartecipanteItem[];
		let innerHtml = data;
		if (array && array.length > 0) {
			innerHtml = array
				.map(function (item) {
					return `${item.Cognome} ${item.Nome} `;
				})
				.join(", ");
		}
		var result = `<div style='display:block; overflow:hidden;max-height:30px;'>${innerHtml}</div>`;

		return result;
	}

	getAnagraficaDistributori() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI)
			.then(res => {
				self.distributori = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT, null, null, "Estratti Conto");
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onEdit(item: ElencoAdesioniPerPagamentoEditItem) {
		var self = this;

		self.editItem = Object.assign(new ElencoAdesioniPerPagamentoEditItem(), item);
		self.editTitle = item.ragioneSociale;
		self.editSubTitle = "";
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;

		api.updateregistrazionePagamento(_ENDPONT, this.editItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.registrazionePagamento") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	get detailTemplate() {
		if (this.showDetail) return "detailTemplate";
		else return "";
	}



	////// TOTALI

	getDaPagareTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			for(var i = 0; i < this.itemsGrid.length; i++)
				result += ( this.itemsGrid[i].totalePremioDaPagare || 0 );
		}

		return result;
	}

	getScontoTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			for(var i = 0; i < this.itemsGrid.length; i++)
				result += ( this.itemsGrid[i].importoSconto || 0 );
		}

		return result;
	}

	getPagatoTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			for(var i = 0; i < this.itemsGrid.length; i++)
				result += ( this.itemsGrid[i].importoPagato || 0 );
		}

		return result;
	}

	getSaldoTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			for(var i = 0; i < this.itemsGrid.length; i++)
				result += ( this.itemsGrid[i].saldo || 0 );
		}

		return result;
	}

	calcolaTotaliGenerali(){
		this.totaliGenerali = {};
		var self = this;
		api.get(`${_ENDPONT_TOTALI}?soloSaldoPositivo=${this.showOnlyECbalancePositive}`).then(res =>{
			self.totaliGenerali = res || {};
		})
	}

	getDaPagareGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.daPagare || 0 : 0;
	}

	getScontoGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.sconto || 0 : 0;
	}

	getPagatoGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.pagato || 0 : 0;
	}

	getSaldoGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.saldo || 0 : 0;
	}
}
