export default class ElencoAdesioniPerPagamentoEditItem {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
    	this.totalePremio = null;
    	this.importoSconto = null;
    	this.importoPagato = null;
    	this.dataPagamento = null;
    	this.saldo = null;
		this.ragioneSociale = "";
		this.totalePremioDaPagare = null;
	}

    itemID: number;
    totalePremio: number | null;
    importoSconto: number | null;
    importoPagato: number | null;
    dataPagamento: Date | string | null;
    saldo: number | null;
	ragioneSociale: string;
	totalePremioDaPagare: number | null;
}